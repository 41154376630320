/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Swiper Slides
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper";
@import "~animate.css/animate.min";
// @import "~swiper/swiper-bundle";


@font-face {
  font-family: "Ubuntu";
  src: url("/assets/fonts/Ubuntu/Ubuntu-Regular.ttf");
}

// Font Poppins
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

// Font Montserrat
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
}

html {
  font-size: 10px;
}

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (min-width: 959px) {
  html {
    font-size: 10px;
  }
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 958px) {
  html {
    font-size: 14px;
  }
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
  html {
    font-size: 13px;
  }
}

body {
  background: #f5f6fa;
}

:root {
  --ion-font-family: "Poppins-Regular";
  --max-page-width: 1280px;
  --color-principal: #51438e;
  --tabbar-width: 95px;
  font-size: 13px;
  --background: #f5f6fa;
  --ion-color-atendido: #8dd3f7;
  /*cores dos marcadores*/
  --marcador-color-verde: #30bea4;
  --marcador-color-vermelho: #ff4953;
  --marcador-color-01: #4fce89;
  --marcador-color-02: #10b15a;
  --marcador-color-03: #4fce89;
  --marcador-color-04: #30bea4;
  --marcador-color-05: #30bea4;
  --marcador-color-06: #1f7d6c;
  --marcador-color-07: #f2c845;
  --marcador-color-08: #fc8f54;
  --marcador-color-09: #ff4953;
  --marcador-color-10: #7b61ff;
  --marcador-color-11: #7b61ff;
  --marcador-color-12: #3b5998;
  --marcador-color-13: #ffffff;
  --marcador-color-14: #c0c0c0;
  --marcador-color-15: #999999;
  --marcador-color-16: #808080;
  --marcador-color-17: #333333;
  --marcador-color-18: #000000;
  --marcador-color-19: #993300;
  --marcador-color-20: #ff00ff;
  --marcador-color-21: #00ffff;
  --marcador-color-22: #00ccff;
  --marcador-color-23: #333399;
  --marcador-color-24: #0000ff;
  --marcador-color-25: #ffff99;
  --marcador-color-26: #ffcc99;
  --marcador-color-27: #ff99cc;
  --marcador-color-28: #30bea4;
  --marcador-color-29: #30bea4;
  --marcador-color-30: #30bea4;
  --marcador-color-31: #30bea4;
  --marcador-color-32: #30bea4;
  --marcador-color-33: #30bea4;
  --marcador-color-34: #30bea4;
  --marcador-color-35: #30bea4;
  --marcador-color-36: #30bea4;
  /*cores dashboard*/
  --dashboard-color-blue: #2e96f5;
  --dashboard-color-green: #24d591;
  --dashboard-color-orange: #ffc145;
  --dashboard-color-red: #fc8f54;

  /** quaternary **/
  --ion-color-quaternary: #fab324;
  --ion-color-quaternary-rgb: 250, 179, 36;
  --ion-color-quaternary-contrast: #000000;
  --ion-color-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-quaternary-shade: #dc9e20;
  --ion-color-quaternary-tint: #fbbb3a;
  --ck-z-default: 501;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
  --ck-border-radius: 15px;

}


// .ck.ck-balloon-panel {
//   z-index: 1051 !important;
// }

.ck-balloon-panel {
  z-index: 9999 !important
}


/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tipo-atendimento-col,
.especialidades-col {
  width: 50%;
}

.Rounded-profile-photo {
  border: 5px solid;
  border-color: var(--marcador-color-07);
}

.radio-blue,
.radio-dark-blue,
.radio-green,
.radio-yellow,
.radio-orange,
.dashboard-radio {
  width: 8px;
  height: 8px;
  margin-right: 7px;
}

.dashboard-radio {
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}

.field-container {
  --background: #f5f6fa;
  --border-width: 0; // para esconder o line do ion-item sem precisar usar lines="none"
  --padding-start: 0;
  --inner-padding-end: 0;
  --show-full-highlight: 0; // para não mostrar sublinhado no campo com foco
  margin-right: 16px;
  margin-top: 13px;

  ion-label {
    height: 18px;
    margin-left: 12px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;

    font-family: "Poppins-Regular" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  ion-input,
  ion-textarea,
  ion-datetime-button {
    height: 31px;
    margin-bottom: 5px;
    margin-top: 5px;

    --background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9px;

    // placeholder
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    font-family: "Poppins-Regular";
    font-style: normal;
    --placeholder-font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    --placeholder-color: rgba(99, 106, 125, 0.65);
  }

  textarea {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  ion-select {
    height: 31px;
    margin-bottom: 5px;
    margin-top: 5px;




    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9px;

    // placeholder
    --padding-start: 12px !important;
    font-family: "Poppins-Regular";
    font-style: normal;
    --placeholder-font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    --placeholder-color: rgba(99, 106, 125, 0.65);
  }

  ion-select::part(placeholder),
  ion-select::part(text) {
    color: #4d556a;
    white-space: normal !important;
  }

  ion-select::part(icon) {
    color: #4d556a;
    height: inherit;
    margin-top: 0;
    //margin-bottom: auto;
    margin-right: 11px;
    content: url("assets/icon/select-arrow.svg");
    width: 14px;
  }

  // Force text-wrap on <ion-select-option>'s when shown in an Ionic Alert
  ion-alert.select-alert {
    // These widths are pretty arbitrary, so you're use-case(s) could required different widths:
    --width: auto !important;
    --max-width: 500px !important;

    .select-interface-option .alert-radio-label,
    .select-interface-option .alert-checkbox-label {
      white-space: normal !important;
    }
  }



}


// .buttons-container {
//   margin-top: 32px;
//   margin-right: var(--tabbar-width);
//   padding-right: 27px;
//   display: flex;
//   justify-content: center;

//   .cancelar-button {
//     height: 39px;
//     --background: #FFFFFF;
//     --border-color: #5B61AC;
//     --border-radius: 11px;
//     --border-style: solid;
//     --border-width: 1px;
//     text-transform: unset;
//     margin-left: 13px;
//     margin-right: 13px;
//     font-family: 'Poppins-Medium';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;
//     color: #5B61AC;
//   }

//   .salvar-button {
//     height: 39px;
//     --background: #5B61AC;
//     --border-radius: 11px;
//     margin-left: 13px;
//     margin-right: 13px;
//     font-family: 'Poppins-Medium';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18.2139px;
//     color: #FFFFFF;
//     text-transform: none;
//   }
// }

// para personalizar a janela (popup) do ion-select
ion-popover::part(content) {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  --ion-font-family: "Poppins-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  white-space: normal !important;
  --width: auto;
}

.title-group {
  margin-left: 10px;
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.flex-columns {
  display: flex;
  flex-direction: row;
}

.flex-rows {
  display: flex;
  flex-direction: column;
}

.line-fields {
  display: flex;
}

ion-datetime-button::part(native) {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  background: none;
}

.calendar-button {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #5b61ac;
  text-transform: none;
  margin-left: 0;
  box-sizing: border-box;
  width: 271px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 9px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
}

.calendar-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.modal-assunto-cadastro,
.modal-estagio-funil-cadastro,
.modal-incluir-agendamento {
  justify-content: flex-end;
  align-items: flex-start;
  --height: 100%;
}

.modal-Cadastro-Tutor-agendamento {
  --background: transparent;
  backdrop-filter: blur(5px);
}

.modalCard-pet-agendamento {
  backdrop-filter: blur(5px);

  --width: 50%;
  --height: auto;
  --background: transparent;
  --box-shadow: none !important;
}

.add-action-modal {
  backdrop-filter: blur(5px);

  --width: 30rem;
  --height: auto;
  --background: transparent;
  --box-shadow: none !important;
}

ion-select-popover {
  width: 100%; // 270px
}

/*custom-alert*/

.custom-alert .alert-wrapper {
  height: 250px;
  width: 480px;
  min-width: 480px;
  max-width: 480px;
  border-radius: 15px;
}

.custom-alert .alert-head {
  height: 35px;
  padding: 4px 11px 4px 36px;
  background: #5b61ac;
  display: flex;
  justify-content: flex-end;

  img {
    margin-top: 7px;
  }
}

.custom-alert .alert-title {
  color: white;
  font-family: Poppins-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.custom-alert .alert-message {
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #403f4c;
  padding-top: 15px !important;

  span {
    font-family: Poppins-SemiBold;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ff4953;
  }
}

.custom-alert .alert-button-group {
  display: flex;
  justify-content: space-between;
  padding: 20px 67px 25px 68px;
}

.custom-alert .alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

.custom-alert-button-confirm {
  text-transform: capitalize !important;
  background: #5b61ac !important;
  color: white !important;
  border-radius: 50px !important;
  width: 142px !important;
  height: 40px !important;

  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 27px;

  .alert-button.sc-ion-alert-md {
    text-align: center !important;
  }
}

.custom-alert-button-cancel {
  text-transform: capitalize !important;
  background: white !important;
  color: #5b61ac !important;
  border-radius: 50px !important;
  border: 3px solid #5b61ac !important;
  width: 142px !important;
  height: 40px !important;

  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 27px;

  .alert-button.sc-ion-alert-md {
    text-align: center !important;
  }
}

.filter-slider-icon {
  color: rgb(77, 85, 106);
  border: 1px solid #ccc;
  padding: 1px;
  border-radius: 2px;
  margin-inline-start: 5px;
}

.order-button {
  --padding-start: 7px;
  --padding-end: 4px;
  height: 24px;
  --background: #ffffff;
  --border-color: #ccc;
  --border-radius: 7px;
  --border-style: solid;
  --border-width: 1px;

  border-radius: 7px;
  margin-left: 11px;
  --box-shadow: none;
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #4d556a;
  text-transform: none;
}

.clientes-ativos-button,
.filter-button {
  --padding-start: 6px;
  --padding-end: 6px;
  height: 24px;
  --border-color: #ccc;
  --background: #ffffff;
  --border-radius: 7px;
  --border-style: solid;
  --border-width: 1px;
  border-radius: 7px;
  margin-left: 11px;

  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #4d556a;
  text-transform: none;
  --box-shadow: none;
}

.filter-clear-button {
  --padding-start: 7px;
  --padding-end: 4px;
  height: 24px;
  margin-left: 11px;

  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #4d556a;
  text-transform: none;
}

.offset-bottom-100 {
  padding-bottom: 100px !important;
}

.no-left-spaces {
  padding-left: 0 !important;
  margin-left: 0 !important;
  --padding-start: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.no-spaces {
  padding: 0 !important;
  margin: 0 !important;
  --padding: 0 !important;
  --margin: 0 !important;
}

.height-100 {
  height: 100%;
  min-height: 100%;
}

.no-horizontal-spaces {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.display-flex {
  display: flex;
}

.bolder-label {
  font-weight: bolder;
}

.no-background {
  background: none !important;
  --background: none !important;
}

.scroll-y {
  background: #f5f6fa;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.white-background {
  background: #fff;
  --background: #fff;
}

.page-content {
  background: #f5f6fa;
}

.clickable {
  cursor: pointer;
}

.card-flex-line {
  display: flex;
}

.body.backdrop-no-scroll {
  height: 100%;
}

.removeProfAlertClass {
  border-top: 20px solid var(--ion-color-secondary) !important;
}

.default-input {
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  --min-height: 25px !important;
  --height: 25px;
  padding: 10px;
  --padding: 10px;
  --padding-start: 10px !important;
  --padding-end: 10px !important;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-top {
  border-top: 1px solid #ccc;
}

.empty-icon {
  font-size: 15rem;
  margin-top: 5%;
  opacity: 0.4;
}

.empty-text {
  color: var(--ion-color-medium);
  font-size: 1.5rem;
}


.ck.ck-content {
  height: 22rem;
  border-radius: 15px;
  min-width: 48rem;


}



.ck-editor__editable {
  border-radius: 0px 0px 15px 15px !important;
  border-top: 0px !important;
}

.ck-toolbar {
  border-radius: 15px 15px 0px 0px !important;
  border-bottom: 0px !important;
}
.tooltip-event-pet-name{
  color:#fff !important;
}

// body {
//   --ck-z-default: 100;
//   --ck-z-modal: calc(var(--ck-z-default) + 999);
// }

// .ck .ck-labeled-field-view {
//   z-index: 999999 !important;
// }





.modal-fullscreen {
  backdrop-filter: blur(5px);
  --width: auto;
  --height: auto;
  --max-height: 100%;
  --background: transparent;
  --box-shadow: none !important;

}

.page-loader {
  border-color: #9ca5ff rgba(0, 0, 0, 0) #9ca5ff rgba(0, 0, 0, 0) !important;
}

.page-progressbar {
  background: #51438e !important;
}

.edit-user-modal {
  --border-radius: 20px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

@media screen and (max-width: 900px) {
  .hide-mobile {
    display:none !important;
    visibility: hidden !important;
  }
}

@media screen and (min-width: 901px) {
  .hide-desktop {
    display:none !important;
    visibility: hidden !important;
  }
}
.popover-filter-item{
  min-width: 150px !important;
}
.transition-ease{    
  transition: 2s ease;
}
.fade-in-effect{
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 2s;    
  -webkit-animation-delay: 2s;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #f9f8f8;
}

::-webkit-scrollbar-thumb {
  background: #e5c8fd;
  -webkit-border-radius: 1ex;  
}

::-webkit-scrollbar-corner {
  background: #f9f8f8;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #e5c8fd #f9f8f8;
}

body::-webkit-scrollbar {
  width: 2px;
}

body::-webkit-scrollbar-thumb {
  background-color: #f9f8f8;
  border-radius: 0.7rem;
}

body::-webkit-scrollbar-track {
  background-color: #f9f8f8;
  border-radius: 0.7rem;
}
.scroller {
  scrollbar-width: thin;
}
.scroller {
  --scrollbar-width: thin;
}

.calendar-day{
  background: #baf4b5 !important;
}




// ion-content {
//   // overwrite inline styles
//   // --offset-bottom: auto !important;
//   --overflow: hidden;

//   &::-webkit-scrollbar {
//     display: none;
//   }
// }

// .custom-scrollbar .alert-tappable.sc-ion-alert-md ::-webkit-scrollbar {

//   width: 2px !important;
//   scrollbar-width: thin !important;
// }

// .custom-scrollbar .alert-tappable.sc-ion-alert-md ::-webkit-scrollbar-track {
//   background-color: #F5F5F5;
//   border-radius: 10px;
// }

// .custom-scrollbar .alert-tappable.sc-ion-alert-md ::-webkit-scrollbar-thumb {
//   background-color: #A9A
// }
/**************/
