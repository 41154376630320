.cal-hour-segment{
    
}

.cal-time{  
  font-size: 1.6em;
  color: var(--ion-color-secondary);
  top:25%;
  position: absolute;

}
.day-number{
  font-size:1.2em;
  color: #473e70;
  font-weight:bolder;
}
.day-label{
  font-size:1.3em;
  color: var(--ion-color-secondary)
}
.cal-today{
  background:#efeafe;
}
.cal-month-view{

  .cal-day-cell.cal-today{
    background:#efeafe;
  }
  .cal-event{
    width: 15px;
    height: 15px;
  }

  .cal-header{
    font-size:1.3em;
    color:var(--ion-color-secondary)
  }

  .completo{
    background-color: var(--ion-color-medium) !important;
    background: var(--ion-color-medium) !important;
  }
  .confirmado{
    background-color: var(--ion-color-success) !important;
    background: var(--ion-color-success) !important
  }
  .emAtendimento{
    background-color: var(--ion-color-ematendimento) !important;
    background: var(--ion-color-ematendimento) !important
  }
  .cancelado{
    background-color: var(--ion-color-danger) !important;
    background: var(--ion-color-danger) !important;
  }
  .agendado{
    background-color: var(--ion-color-warning) !important;
    background: var(--ion-color-warning) !important;
  }
  .noshow{
    background-color: #262525 !important;
    background: #262525 !important;
  }
  .atendido{
    background-color:  #8dd3f7  !important;
    background:  #8dd3f7  !important;
  }
  .atrasado{
    background-color: var(--ion-color-primary) !important;
    background: var(--ion-color-primary) !important;
  }

}

.cal-day-number{
  color:#000;
  opacity: 1 !important;
}
.cal-event-title{
  color: var(--ion-color-secondary) !important;
}
